import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateProduct: {},
    isLoading: false,
   error : null
}

const updateProductSlice = createSlice({
    name: 'updateProduct',
    initialState,
    reducers:{
        updateProductPending: (state) => {
            state.isLoadingProducts = true;
        },
        updateProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.updateProduct = payload?.updated;
            state.error = null;
        },
        updateProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {updateProductPending, updateProductSuccess, updateProductFail} = updateProductSlice.actions

export default updateProductSlice.reducer