import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateduser: {},
    isLoading: false,
    error: null
}

const updateUserSlice = createSlice({
    name: 'updateduser',
    initialState,
    reducers:{
        updateUserPending: (state) => {
            state.isLoadingUpdateUser = true;
        },
        updateUserSuccess: (state, {payload}) => {
            state.isLoadingUpdateUser = false;
            state.updateduser = payload?.data;
            state.error = null
        },
        updateUserFail: (state, {payload}) => {
            state.isLoadingUpdateUser = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {updateUserPending, updateUserSuccess, updateUserFail} = updateUserSlice.actions

export default updateUserSlice.reducer