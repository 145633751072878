import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addeduser: {},
    isLoading: false,
 error : null
}

const AddUserSlice = createSlice({
    name: 'addeduser',
    initialState,
    reducers:{
        addPending: (state) => {
            state.isLoading = true;
        },
        addSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addeduser = payload?.data;
            state.error = null;
        },
        addFail: (state, {payload}) => {
            state.isLoading = false;
            state.error =Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {addPending, addSuccess, addFail} = AddUserSlice.actions

export default AddUserSlice.reducer