import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addProduct: {},
    isLoading: false,
     error : null
}

const addProductSlice = createSlice({
    name: 'addProduct',
    initialState,
    reducers:{
        addProductPending: (state) => {
            state.isLoadingProducts = true;
        },
        addProductSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addProduct = payload?.product;
            state.error = null;
        },
        addProductFail: (state, {payload}) => {
            state.isLoading = false;
            state.error =  Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {addProductPending, addProductSuccess, addProductFail} = addProductSlice.actions

export default addProductSlice.reducer