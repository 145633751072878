import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editCategory: {},
    isLoading: false,
    error: null
}

const editCategorySlice = createSlice({
    name: 'editCategory',
    initialState,
    reducers:{
        editCategoryPending: (state) => {
            state.isLoading = true;
        },
        editCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editCategory = payload?.response;
            state.error = null;
        },
        editCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error =  Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {editCategoryPending, editCategorySuccess, editCategoryFail} = editCategorySlice.actions

export default editCategorySlice.reducer