import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addsub: {},
    isLoading: false,
    error: null
}

const addSubCategorySlice = createSlice({
    name: 'addsub',
    initialState,
    reducers:{
        addSubCategoryPending: (state) => {
            state.isLoading = true;
        },
        addSubCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addsub= payload?.add;
            state.error = null;
        },
        addSubCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error =  Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {addSubCategoryPending, addSubCategorySuccess, addSubCategoryFail} = addSubCategorySlice.actions

export default addSubCategorySlice.reducer