import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    add: {},
    isLoading: false,
    error: null
}

const AddShopSlice = createSlice({
    name: 'add',
    initialState,
    reducers: {
        addPending: (state) => {
            state.isLoading = true;
        },
        addSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.add = payload?.data;
            state.error = null;
        },
        addFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const { addPending, addSuccess, addFail } = AddShopSlice.actions

export default AddShopSlice.reducer