import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dashboard: {},
    loading: false,
    error: null
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers:{
        dashboardPending: (state) => {
            state.loading = true;
        },
        dashboardSuccess: (state, {payload}) => {
            state.loading = false;
            state.dashboard = payload?.dashboard;
            state.error = null;
        },
        dashboardFail: (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        }
    }
})

export const {dashboardPending, dashboardSuccess, dashboardFail} = dashboardSlice.actions

export default dashboardSlice.reducer