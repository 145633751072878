import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editSubCategory: {},
    isLoading: false,
    error: null
}

const editSubCategorySlice = createSlice({
    name: 'editSubCategory',
    initialState,
    reducers:{
        editSubCategoryPending: (state) => {
            state.isLoading = true;
        },
        editSubCategorySuccess: (state, {payload}) => {
            state.isLoading = false;
            state.editSubCategory = payload?.response;
            state.error = null;
        },
        editSubCategoryFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {editSubCategoryPending, editSubCategorySuccess, editSubCategoryFail} = editSubCategorySlice.actions

export default editSubCategorySlice.reducer