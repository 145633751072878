import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import getProductsReducer from "../features/products/productSlices/productSlice";
import LoginReducer  from "../features/user/userSlices/LoginSlice"
import getUsersReducer from "../features/user/userSlices/userSlice"
import getCategoriesReducer from "../features/categories/categorySlices/categorySlice"
import profileReducer from '../features/settings/profilesettings/slices/profileSlice';
import addProductReducer from '../features/products/productSlices/addProductSlice'
import shopReducer from '../features/shops/shopSlices/shopSlice';
import addCategoryReducer from '../features/categories/categorySlices/addCategorySlice';
import editCategoryReducer from '../features/categories/categorySlices/editCategorySlice';
import deleteCategoryReducer from '../features/categories/categorySlices/deleteCategorySlice';
import deleteProductReducer from '../features/products/productSlices/deleteProductSlice';
import updateProductReducer from '../features/products/productSlices/updateProductSlice';
import addSubCategoryReducer from '../features/subcategories/subcategorySlices/addSubCategorySlice';
import editSubCategoryReducer from '../features/subcategories/subcategorySlices/editSubCategorySlice';
import deleteSubCategoryReducer from '../features/subcategories/subcategorySlices/deleteSubCategorySlice';
import dashboardReducer from '../features/dashboard/dashboardSlices/dashboardSlice';
import AddUserReducer from '../features/user/userSlices/AddUserSlice';
import EditUserReducer from '../features/user/userSlices/EditUserSlice';
import LogoutReducer from '../features/user/userSlices/LogoutSlice';
import AddShopReducer from '../features/shops/shopSlices/AddShopSlice';
import UpdateShopReducer from '../features/shops/shopSlices/UpdateShopSlice';


const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  getProductsList: getProductsReducer,
  getUserList: getUsersReducer,
  categoryList : getCategoriesReducer,
  authProfile: profileReducer,
  authUser : LoginReducer,
  addproducts : addProductReducer,
  addcategories: addCategoryReducer,
  shops: shopReducer,
  editcategory: editCategoryReducer,
  deletesCategory: deleteCategoryReducer,
  updatingProduct: updateProductReducer,
  deletingProduct: deleteProductReducer,
  addsubcategory: addSubCategoryReducer,
  editsubcategory: editSubCategoryReducer,
  deletesubcategory: deleteSubCategoryReducer,
  dash: dashboardReducer,
  adduser : AddUserReducer,
  edituser: EditUserReducer,
  logout : LogoutReducer,
  addingshop: AddShopReducer,
  updatingshop: UpdateShopReducer,

};

export default configureStore({
  reducer: combinedReducer,
});
