import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list: {},
    isLoading: false,
 error : null
}

const shopSlice = createSlice({
    name: 'list',
    initialState,
    reducers:{
        shopPending: (state) => {
            state.isLoading = true;
        },
        shopSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.list = payload?.data;
            state.error = null;
        },
        shopFail: (state, {payload}) => {
            state.isLoading = false;
            state.error =  Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const {shopPending, shopSuccess, shopFail} = shopSlice.actions

export default shopSlice.reducer