import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    update: {},
    isLoading: false,
    error: null
}

const UpdateShopSlice = createSlice({
    name: 'update',
    initialState,
    reducers: {
        updatePending: (state) => {
            state.isLoading = true;
        },
        updateSuccess: (state, { payload }) => {
            console.log("Slice: ", payload.data);
            state.isLoading = false;
            state.update = payload.data;
            state.error = null;
        },
        updateFail: (state, { payload }) => {
            state.isLoading = false;
            state.error = Array.isArray(payload?.errors) ? payload : payload?.message;
        }
    }
})

export const { updatePending, updateSuccess, updateFail } = UpdateShopSlice.actions

export default UpdateShopSlice.reducer